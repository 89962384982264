/*******************************************************************************
* Footer
*******************************************************************************/
.page-footer {
  background-color: $primary-color;
  color: #fff;
  font-weight: $light-weight;
  padding: 20px 0 12px;
  text-align: center;

  p {
    font-weight: inherit;
    margin-bottom: 10px;
  }

  ul {
    margin: 0;
  }

  li {
    @include inline-block;
    padding-left: 20px;
    position: relative;

    &::before {
      background-color: #fff;
      border-radius: 50%;
      content: '';
      height: 5px;
      left: 7px;
      position: absolute;
      top: 9px;
      width: 5px;
    }

    &:first-child {
      padding-left: 0;

      &::before {
        display: none;
      }
    }
  }

  a {
    color: inherit;
    font-weight: inherit;
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
      text-decoration: underline;
    }
  }
}

.page-footer__logo {
  @include inline-block;
  margin-bottom: 25px;
  max-width: 154px;
}

.goto-top {
  background-color: $primary-color;
  bottom: 40px;
  box-shadow: 0 0 20px rgba($black, 0.5);
  color: $white;
  display: block;
  height: 45px;
  opacity: 0;
  position: fixed;
  right: 15px;
  text-align: center;
  width: 45px;
  z-index: 1000;
  border:1px solid  $secondary-color;


  em {
    @extend %fontsize20;
    @include transition(all, 0.2s, ease, 0s);
    @include horizontal-vertical-align(absolute);
    color: $secondary-color;
  }

  &.visible {
    opacity: 1;
  }

  &:hover {
    background-color: $secondary-color;

    em {
      color: $primary-color;
    }
  }
}

@include media-breakpoint-up(md) {
  .page-footer {
    padding: 30px 0;

    ul {
      li {
        padding-left: 25px;

        &::before {
          left: 11px;
          top: 8px;
        }
      }
    }
  }

  .page-footer__logo {
    max-width: 140px;
  }
}

@include media-breakpoint-up(lg) {
  .page-footer {
    ul {
      li {
        padding-left: 30px;

        &::before {
          left: 12px;
          top: 12px;
        }
      }
    }
  }

  .page-footer__logo {
    margin-bottom: 0;
    max-width: 100%;
  }

  .goto-top {
    bottom: 20px;
    height: 55px;
    right: 30px;
    width: 55px;
  }
}
