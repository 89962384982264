//****************************************************************************
//  Font sizes extensions
//***************************************************************************/

%fontsize117 {
  font-size: 117px;
  line-height: 155px;
}

%fontsize40 {
  font-size: 20px;
  line-height: 27px;
}

%fontsize30 {
  font-size: 17px;
  line-height: 23px;
}

%fontsize20 {
  font-size: 15px;
  line-height: 20px;
}

%fontsize18 {
  font-size: 15px;
  line-height: 20px;
}

@include media-breakpoint-up(lg) {
  %fontsize40 {
    font-size: 40px;
    line-height: 55px;
  }

  %fontsize30 {
    font-size: 30px;
    line-height: 41px;
  }

  %fontsize20 {
    font-size: 20px;
    line-height: 27px;
  }

  %fontsize18 {
    font-size: 18px;
    line-height: 24px;
  }
}
