/*******************************************************************************
* Button
*******************************************************************************/
.btn {
  @extend %fontsize20;
  @include transition(all, 0.3s, ease-in-out, 0s);
  @include inline-block();
  background: $primary-color;
  border: 2px solid $primary-color;
  border-radius: 0;
  box-shadow: none;
  color: $white;
  font-weight: $semi-bold-weight;
  margin: 0;
  outline: 0;
  padding: 8px 15px;
  position: relative;
  white-space: normal;
  width: 100%;
  z-index: 3;

  &:hover,
  &:focus,
  &:active {
    @include box-shadow(none);
    background: $white;
    color: $primary-color;
    outline: none;
    text-decoration: none;

    &::before {
      opacity: 0;
    }
  }

  &--secondary {
    background: $secondary-color;
    border-color: $secondary-color;
    color: $primary-color;

    &:hover,
    &:focus,
    &:active {
      border-color: $secondary-color-light;
      background: $secondary-color-light;
    }
  }

  &--icon {
    em {
      @include rotate(90deg);
      margin-right: 8px;
    }
  }
}

@include media-breakpoint-up(sm) {
  .btn {
    width: auto;
  }
}

@include media-breakpoint-up(md) {
  .btn {
    padding: 10px 27px;
  }
}

@include media-breakpoint-up(lg) {
  .btn {
    padding: 12px 45px 14px;
  }
}
