//****************************************************************************
//  Bootsrap variables overrides
//***************************************************************************/
$font-family-sans-serif: 'Open Sans', monospace, sans-serif;
$font-primary: $font-family-sans-serif;
$font-secondary: 'Segoe UI', $font-primary; // Segoe UI Fonts will come there once client provide
$btn-box-shadow: none;

//****************************************************************************
//  Custom variables
//***************************************************************************/
$primary-color: #004165;
$secondary-color: #f0ab00;
$secondary-color-light: #f3bc33;
$text-color: $primary-color;
$text-dark-color: #002133;
$text-muted-color: #707070;

$body-color: $text-color;
$gray-color: #f4f7fb;
$gray-light-color: #eff2f6;
$gray-medium-color: #cfcfcf;
$dark-gray-color: #494949;

$input-color: $body-color;
$input-error-color: #dd4967;
$white-color:#ffffff;

//****************************************************************************
//  Font Weight
//***************************************************************************/
$light-weight: 300;
$regular-weight: 400;
$semi-bold-weight: 600;
$bold-weight: 700;

//****************************************************************************
//  Gutter Width
//***************************************************************************/
$grid-gutter-width: 2rem;
