/*******************************************************************************
* Headline Block
*******************************************************************************/
.headline-block {
  margin-bottom: 25px;

  a {
    padding-left: 25px;
    position: relative;

    em {
      @include rotate(90deg);
      left: 0;
      position: absolute;
      top: 4px;
    }
  }

  &.text-center {
    h2::after {
      @include transform(translateX(-50%));
      left: 50%;
    }
  }
}

.line {
  display: inline-block;
  padding-bottom: 10px;
  position: relative;

  &::after {
    background-color: $secondary-color;
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 63px;
  }

  &--right {
    &::after {
      left: auto !important;
      right: 0 !important;
    }
  }

  &--center {
    &::after {
      @include transform(translateX(-50%));
      left: 50% !important;
    }
  }
}

@include media-breakpoint-up(md) {
  .headline-block {
    margin-bottom: 30px;
  }
}

@include media-breakpoint-up(lg) {
  .headline-block {
    margin-bottom: 40px;

    a {
      padding-left: 40px;

      em {
        top: 8px;
      }
    }
  }

  .line {
    &::after {
      height: 4px;
    }
  }
}
