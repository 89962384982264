/*******************************************************************************
* Hero Banner
*******************************************************************************/
.hero-banner-section {
  position: relative;

  .button-wrap {
    margin-top: 20px;
  }
}

.hero-banner__bg {
  @include flexbox;
  @include align-items(flex-end);
  border-bottom: 4px solid $secondary-color;
  border-top: 4px solid $secondary-color;
  min-height: 200px;
  padding: 30px 20px;
  width: 100%;

  .headline-block {
    color: $white;
    margin-bottom: 0;
    margin-left: auto;
    max-width: 50%;

    h3 {
      font-size: 15px;
      line-height: normal;
      margin-bottom: 0;
    }
  }
}

.hero-banner-teaser {
  padding: 15px 0;
}

.headline__map {
  align-items: center;
  display: flex;
  margin-bottom: 20px;

  h4 {
    font-size: 20px;
    font-weight: $bold-weight;
    line-height: 27px;
    margin-bottom: 0;
    padding-bottom: 10px;
    position: relative;

    &::after {
      background-color: $secondary-color;
      bottom: 0;
      content: '';
      height: 2px;
      left: 0;
      position: absolute;
      width: 35px;
    }
  }
}

.map__image {
  margin-right: 15px;
  max-width: 66px;
}

@include media-breakpoint-up(md) {
  .hero-banner__bg {
    min-height: 360px;
    padding: 70px 15px 30px;

    .headline-block {
      h3 {
        margin-bottom: 20px;
      }
    }
  }

  .hero-banner-teaser {
    padding: 30px 0;

    .button-wrap {
      margin-top: 30px;
    }
  }

  .headline__map {
    display: block;
    margin-bottom: 0;

    h4 {
      margin-bottom: 10px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .hero-banner-section {
    @include flexbox;
    @include align-items(flex-end);
    border-bottom-width: 0;
    color: $white;
    min-height: calc(100vh - 327px);
    padding: 100px 0;

    &::after {
      content: '';
      font-size: 0;
      min-height: inherit;
    }

    .button-wrap {
      margin-top: 40px;
    }

    .headline-block {
      max-width: 100%;
      position: relative;
      right: auto;
      top: auto;
    }

    .container {
      position: relative;
    }

    li {
      &::before {
        background-color: $white !important;
      }
    }
  }

  .hero-banner__bg {
    @include absoluted-fullwidth;
    border-bottom-width: 1px;
    border-top: 0;
  }

  .hero-banner-teaser {
    padding: 0;
  }

  .headline__map {
    h4 {
      padding-bottom: 0;

      &::after {
        display: none;
      }
    }
  }
}
