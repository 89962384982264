/****************************************************************************
 * Fonts
 ***************************************************************************/

// Open Sans Light
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../font/Open-Sans-Light.eot');
  src: url('../font/Open-Sans-Light.eot?#iefix') format('embedded-opentype'),
    url('../font/Open-Sans-Light.woff2') format('woff2'),
    url('../font/Open-Sans-Light.woff') format('woff'),
    url('../font/Open-Sans-Light.ttf') format('truetype'),
    url('../font/Open-Sans-Light.svg#Open-Sans-Light') format('svg');
}

// Open Sans Regular
@font-face {
  font-family: 'Open Sans';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('../font/Open-Sans-Regular.eot');
  src: url('../font/Open-Sans-Regular.eot?#iefix') format('embedded-opentype'),
    url('../font/Open-Sans-Regular.woff2') format('woff2'),
    url('../font/Open-Sans-Regular.woff') format('woff'),
    url('../font/Open-Sans-Regular.svg#Open-Sans-Regular') format('svg');
}

// Open Sans Italic
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../font/Open-Sans-Italic.eot');
  src: url('../font/Open-Sans-Italic.eot?#iefix') format('embedded-opentype'),
    url('../font/Open-Sans-Italic.woff2') format('woff2'),
    url('../font/Open-Sans-Italic.woff') format('woff'),
    url('../font/Open-Sans-Italic.ttf') format('truetype'),
    url('../font/Open-Sans-Italic.svg#Open-Sans-Italic') format('svg');
}

// Open Sans SemiBold
@font-face {
  font-family: 'Open Sans';
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  src: url('../font/Open-Sans-SemiBold.eot');
  src: url('../font/Open-Sans-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('../font/Open-Sans-SemiBold.woff2') format('woff2'),
    url('../font/Open-Sans-SemiBold.woff') format('woff'),
    url('../font/Open-Sans-SemiBold.svg#Open-Sans-SemiBold') format('svg');
}

// Open Sans SemiBold Italic
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../font/Open-Sans-SemiBold-Italic.eot');
  src: url('../font/Open-Sans-SemiBold-Italic.eot?#iefix') format('embedded-opentype'),
    url('../font/Open-Sans-SemiBold-Italic.woff2') format('woff2'),
    url('../font/Open-Sans-SemiBold-Italic.woff') format('woff'),
    url('../font/Open-Sans-SemiBold-Italic.ttf') format('truetype'),
    url('../font/Open-Sans-SemiBold-Italic.svg#Open-Sans-SemiBold-Italic') format('svg');
}

// Open Sans Bold
@font-face {
  font-family: 'Open Sans';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: url('../font/Open-Sans-Bold.eot');
  src: url('../font/Open-Sans-Bold.eot?#iefix') format('embedded-opentype'),
    url('../font/Open-Sans-Bold.woff2') format('woff2'),
    url('../font/Open-Sans-Bold.woff') format('woff'),
    url('../font/Open-Sans-Bold.svg#Open-Sans-Bold') format('svg');
}

// SegoeUI Regular
@font-face {
  font-family: 'SegoeUI';
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url('../font/SegoeUI-Regular.woff2') format('woff2'),
    url('../font/SegoeUI-Regular.woff') format('woff');
}

// SegoeUI Semi-Bold
@font-face {
  font-family: 'SegoeUI';
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  src: url('../font/SegoeUI-Semibold.woff2') format('woff2'),
    url('../font/SegoeUI-Semibold.woff') format('woff');
}

// SegoeUI Bold
@font-face {
  font-family: 'SegoeUI';
  font-stretch: normal;
  font-style: normal;
  font-weight: 700;
  src: url('../font/SegoeUI-Bold.woff2') format('woff2'),
    url('../font/SegoeUI-Bold.woff') format('woff');
}

/****************************************************************************
  Body
 ***************************************************************************/
body {
  @extend %fontsize20;
  background-color: $gray-color;
  font-weight: $light-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-bottom: 20px;
}

img {
  height: auto;
  max-width: 100%;
}

figure {
  margin: 0 0 15px;
}

ul {
  list-style-type: none;
  padding: 0;
}


.text-small{
     @extend %fontsize18;
}
/****************************************************************************
  Links
 ***************************************************************************/
a {
  @include transition(all, 0.2s, ease, 0s);
  color: $secondary-color;
  outline: none;
  text-decoration: underline;

  &:hover,
  &:focus {
    color: $primary-color;
  }
}

strong,
b {
  font-weight: $bold-weight;
}

:-moz-selection {
  /* Code for Firefox */
  background: $secondary-color;
  color: $white;
}

::selection {
  background: $secondary-color;
  color: $white;
}

a.da_link{
    color: $primary-color;
    font-weight:600;
    &:hover{
        color: $primary-color;
    }
}

/****************************************************************************
  Headings
 ***************************************************************************/



h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-weight: $light-weight;
}

h3,
.h3{
 font-weight: $regular-weight;
}


h1 {
  @extend %fontsize40;
  font-weight: $bold-weight;
}

h2 {
  @extend %fontsize40;
  font-weight: $bold-weight;
}

h3 {
  @extend %fontsize30;

  a {
    font-weight: $semi-bold-weight;
  }
}

h4 {
  @extend %fontsize20;
  font-weight: $semi-bold-weight;
}

@include media-breakpoint-up(md) {
  p {
    margin-bottom: 26px;
  }
}

@include media-breakpoint-up(lg) {
  p {
    margin-bottom: 26px;
  }
}

@include media-breakpoint-up(xxl) {
  .container {
    max-width: 1656px;
  }
}
