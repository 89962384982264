/*******************************************************************************
* General
*******************************************************************************/
.site-main {
  display: block;
  margin-top: 152px;
  position: relative;
}

.no-scrollbar {
  overflow: hidden;
}

.primary-bg {
  background-color: $primary-color;
}

.secondary-bg {
  background-color: $secondary-color;
}

.bg-img {
  @include bg-img;
}

.bg-img--content {
  margin: 15px 0;
  padding: 50px;

  p {
    color: $white;
    max-width: 40%;
  }
}

.social-media {
  li {
    a {
      @include inline-flexbox();
      @include align-items(center);
    }
  }

  a {
    em {
      margin-right: 10px;
    }
  }
}

@include media-breakpoint-up(md) {
  .site-main {
    margin-top: 88px;
  }
}

@include media-breakpoint-up(lg) {
  .site-main {
    margin-top: 78px;
    min-height: 600px;
  }
}

@include media-breakpoint-up(xl) {
  .site-main {
    margin-top: 127px;
  }
}
