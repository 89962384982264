/*******************************************************************************
* Icon Teaser
*******************************************************************************/

.icon-teaser {
  margin-bottom: 40px;
  text-align: center;

 /*&:hover {
    .icon-teaser__icon {
      color: $secondary-color;
    }
}*/
}

.icon-teaser__icon {
  @extend %fontsize117;
  @include transition(all, 0.3s, ease-in-out, 0s);
  margin-bottom: 0;
}
