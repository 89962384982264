/*******************************************************************************
* PAGE HEADER
*******************************************************************************/
.page-header {
  @include inline-block;
  background-color: $white;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  box-shadow:  0 0 20px rgba(0, 0, 0, 0.8);
}

.header-main {
  padding: 15px 0;
  position: static;
  width: 100%;
}

.button-wrap {
  ul {
    display: flex;
    justify-content: center;

    li {
      padding: 0 7px;

      &:last-child {
        padding-right: 0;
      }

      &:first-child {
        padding-left: 0;
      }
    }

    .btn {
      @include box-shadow(0 3px 6px rgba($black,0.37));
      font-size: 12px;
      line-height: 17px;
      padding: 6px 14px;

      em {
        @include rotate(90deg);
        margin-right: 4px;
      }
    }
  }
}

.brand-logo {
  @include inline-block;
  padding: 10px 0 0;
  text-align: center;
  width: 100%;

  img {
    max-width: 160px;
  }
}

@include media-breakpoint-up(md) {
  .brand-logo {
    text-align: center;
    width: auto;

    img {
      max-width: 218px;
    }
  }

  .header-main {
    @include flexbox();
    @include justify-content(space-between);
  }
}

@include media-breakpoint-up(lg) {
  .header-main {
    margin: 0 auto;
    padding: 0;
  }

  .brand-logo {
    padding: 15px 0;

    img {
      max-width: 240px;
    }
  }

  .button-wrap {
    ul {
      .btn {
        font-size: 18px;
        line-height: 24px;
        min-width: 260px;
        padding: 10px 35px;

        em {
          margin-right: 6px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .brand-logo {
    padding: 25px 0 21px;

    img {
      max-width: 400px;
    }
  }
}
