/****************************************************************************
 * TYPO3 CMS Styles
 ***************************************************************************/
.ce-align-left,
.ce-headline-left {
  text-align: left;
}

.ce-align-center,
.ce-headline-center {
  text-align: center;
}

.ce-align-right,
.ce-headline-right {
  text-align: right;
}

/* Table */
.ce-table {
  max-width: 100%;
  width: 100%;
}

.ce-table th,
.ce-table td {
  padding: 0.5em 0.75em;
  vertical-align: top;
}

.ce-table thead th {
  border-bottom: 2px solid $gray-color;
}

.ce-table th,
.ce-table td {
  border-top: 1px solid $gray-color;
}

.ce-table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba($black, 0.05);
}

.ce-table-bordered th,
.ce-table-bordered td {
  border: 1px solid $gray-color;
}

hr {
  border-top: solid 1px $dark-gray-color;
  margin: 30px 0;
}

.ce-textpic,
.ce-nowrap .ce-bodytext,
.ce-gallery,
.ce-row,
.ce-uploads li,
.ce-uploads div {
  overflow: hidden;
}

.ce-center .ce-outer {
  float: right;
  position: relative;
  right: 50%;
}

.ce-center .ce-inner {
  float: right;
  position: relative;
  right: -50%;
}

.full-width-gallery .ce-gallery figure {
  position: relative;
}

.ce-gallery figure {
  display: table;
  margin: 0;
}

.ce-gallery figcaption {
  caption-side: bottom;
  display: table-caption;
}

.ce-gallery img {
  display: block;
}

.ce-gallery iframe {
  border-width: 0;
}

.ce-border img,
.ce-border iframe {
  border: 2px solid #000;
  padding: 0;
}

.ce-intext.ce-right .ce-gallery,
.ce-intext.ce-left .ce-gallery,
.ce-above .ce-gallery,
.ce-below .ce-gallery {
  margin-bottom: 0;
}

.ce-intext.ce-right .ce-gallery {
  margin-top: 3px;
}

.ce-intext.ce-left .ce-gallery {
  margin-top: 3px;
}

.ce-intext.ce-right .ce-bodytext h5,
.ce-intext.ce-left .ce-bodytext h5 {
  margin-top: 4px;
}

.ce-above .ce-gallery {
  margin-top: 0;
}

.ce-below.ce-right .ce-gallery,
.ce-below.ce-left .ce-gallery,
.ce-below.ce-center .ce-gallery {
  margin-bottom: 0;
  margin-top: 0;
}

.ce-column {
  margin-bottom: 15px;
}

.ce-column:last-child {
  margin-right: 0;
}

.ce-row {
  margin-bottom: 10px;
}

.ce-row:last-child {
  margin-bottom: 0;
}

.ce-above .ce-bodytext {
  clear: both;
}

.ce-intext.ce-left ol,
.ce-intext.ce-left ul {
  overflow: auto;
  padding-left: 40px;
}

.ce-intext.ce-left ul {
  padding-left: 0;
}

.site-main {
  .ce-uploads {
    li {
      padding-left: 0;
    }
  }
}

.ce-uploads {
  li {
    &::before {
      display: none;
    }

    a {
      &:hover {
        color: $primary-color;
      }

      span {
        @include inline-block();
      }
    }
  }

  img {
    float: left;
    margin-right: 10px;
    margin-top: 2px;
  }

  span {
    display: block;
  }
}

.float-left {
  &img {
    margin-right: 20px;
  }
}

.float-right {
  &img {
    margin-left: 20px;
  }
}

.frame {
  .ce-textpic {
    &.ce-left {
      padding-bottom: 0;

      .ce-gallery {
        img {
          margin-bottom: 0;
        }
      }
    }
  }
}

.button-group {
  margin: 20px auto;
}

.cc-window {
  flex-wrap: wrap;
}

.cc-message {
  margin-bottom: 10px;
}

.frame {
  .ce-textpic {
    padding-bottom: 20px;

    img {
      margin-bottom: 10px;
    }
  }
}

.ce-right,
.ce-left {
  .ce-gallery {
    float: none;
    margin: 0 auto;
  }
}

.ce-column {
  float: none;
}

.ce-image {
  img {
    margin-bottom: 10px;
  }
}

.frame {
  .video {
    .video-embed {
      video {
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .ce-textpic {
    &.ce-left {
      padding-bottom: 0;

      .ce-gallery {
        img {
          margin-bottom: 0;
        }
      }
    }
  }

  &.frame-type-textmedia {
    .video-embed {
      padding-bottom: 50px;
    }
  }
}

.frame-space-before-extra-small {
  margin-top: 10px;
}

.frame-space-before-small {
  margin-top: 20px;
}

.frame-space-before-medium {
  margin-top: 30px;
}

.frame-space-before-large {
  margin-top: 40px;
}

.frame-space-before-extra-large {
  margin-top: 50px;
}

.frame-space-after-extra-large {
  margin-bottom: 50px;
}

.frame-space-after-large {
  margin-bottom: 40px;
}

.frame-space-after-medium {
  margin-bottom: 30px;
}

.frame-space-after-small {
  margin-bottom: 20px;
}

.frame-space-after-extra-small {
  margin-bottom: 10px;
}

/* Frame */
.frame-ruler-before::before {
  border-top: 1px solid rgba($black, 0.25);
  content: '';
  display: block;
  margin-bottom: 2em;
}

.frame-ruler-after::after {
  border-bottom: 1px solid rgba($black, 0.25);
  content: '';
  display: block;
  margin-top: 2em;
}

.frame-indent {
  margin-left: 8%;
  margin-right: 8%;
}

.frame-indent-left {
  margin-left: 15%;
}

.frame-indent-right {
  margin-right: 15%;
}
@include media-breakpoint-up(md) {
  .ce-column {
    margin-right: 15px;
  }

  .ce-column:last-child {
    margin-right: 0;
  }

  .ce-left .ce-gallery,
  .ce-column {
    float: left;
  }

  .ce-right .ce-gallery {
    float: right;
  }

  .ce-intext.ce-right .ce-gallery {
    margin-left: 30px;
  }

  .ce-intext.ce-left .ce-gallery {
    margin-right: 30px;
  }
}
@include media-breakpoint-up(lg) {
  .frame-space-before-medium {
    margin-top: 50px;
  }

  .frame-space-before-large {
    margin-top: 65px;
  }

  .frame-space-before-extra-large {
    margin-top: 85px;
  }

  .frame-space-after-extra-large {
    margin-bottom: 85px;
  }

  .frame-space-after-large {
    margin-bottom: 65px;
  }

  .frame-space-after-medium {
    margin-bottom: 50px;
  }

  .frame-indent {
    margin-left: 15%;
    margin-right: 15%;
  }

  .frame-indent-left {
    margin-left: 33%;
  }

  .frame-indent-right {
    margin-right: 33%;
  }
}
