/*******************************************************************************
* Content Section
*******************************************************************************/
.content-section {
  padding: 55px 0 40px;
/*
  br {
    display: none;
  }
*/
  .button-wrap {
    margin-top: 20px;
  }

  &.gray-bg {
    @include linearGradient;
  }

  &.with-border {
    border-top: 3px solid $secondary-color;
  }

  &.dark-bg {
    @include darkReverce;
    color: $white;

    a:not(.btn) {
      &:hover,
      &:focus {
        color: $white;
      }
    }
  }

  &.text-end {
    .headline-block {
      h2 {
        &::after {
          left: auto;
          right: 0;
        }
      }
    }
  }
}

main {
  p {
    a {
      color: $primary-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }
    .container:not(.agb){
      ul {
        list-style: none;

        li {
          margin-bottom: 0;
          padding-left: 20px;
          position: relative;

          &::before {
            @include border-radius(50%);
            background-color: $text-color;
            content: '';
            height: 6px;
            left: 0;
            position: absolute;
            top: 10px;
            width: 6px;
          }
        }

        ul {
          margin: 15px 0;
          padding-left: 15px;
        }
      }
    }

  ol {
    counter-reset: my-badass-counter;
    margin: 15px 0;
    padding: 0;

    li {
      list-style-type: none;
      margin-bottom: 15px;
      padding-left: 35px;
      position: relative;

      &::before {
        content: counter(my-badass-counter, upper-roman) '.';
        counter-increment: my-badass-counter;
        left: 1px;
        position: absolute;
        top: 0;
      }

      ul{
          counter-reset: my-badass-counter;

          li{
              &::before {
               content: ' \26AC';
               counter-increment: unset;
               left: 1px;
               position: absolute;
               top: 0;
             }
          }
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .content-section {
    padding: 60px 0;

    br {
      display: block;
    }

    .button-wrap {
      margin-top: 40px;
    }

    &.dark-bg {
      @include darkGradient;
    }

    &.with-border {
      border-bottom: 3px solid $secondary-color;
      border-top: 0;

      &.gray-bg {
        border-bottom: 0;
      }
    }

    &.text-end {
      .headline-block {
        h2 {
          &::after {
            left: 0;
            right: auto;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .content-section {
    padding: 80px 0 70px;
  }
}
