/* ############### START COOKIEBANNER ############### */

.cb_hidden_content {
    max-height: 0;
    transition: max-height 0.6s;
    overflow: hidden;

    label{
        font-weight: bold;
    }
}

.cb_text {
    margin-bottom: 10px;
}

.show_hc {
    max-height: 1300px;
    transition: max-height 1s;
}

.cookieBanner {
    position: fixed;
    left: 50%;
    background-color: #f2f2f2;
    box-shadow: 0 0 24px 0 #767676;
    padding: 24px 0;
    width: 980px;
    overflow: auto;
    z-index: 900;
    top: 10vh;
    transform: translateX(-50%);

    & .cb_mainWrap {
        margin: auto;
        max-width: 960px;
        padding: 0 12px;

        .cb_text {
            width: calc(100% - 280px);
            float: left;
            margin-bottom: 10px;
        }

        .cb_buttons {
            float: right;
        }

        .cookieBanner table {
            width: 100%;
        }

        .cookieBanner td {
            padding: 10px 0;
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }

        .cookieBanner td button {
            display: inline;
            width: auto;
        }

        .cb_close_content {
            display: none;
            cursor: pointer;
        }
    }
}

@media (max-width: 990px){
    .cookieBanner {
        top: 5vh;
        width: 100%;
    }
}

.cookieBannerBg {
    position: fixed;
    overflow: hidden;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 30;
    background-color: rgba(0,0,0,0.7);
}
