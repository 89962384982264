/**************************************************************************
* Form
***************************************************************************/
@mixin select-svg($color: $text-muted-color) {
  background-image: inline-svg('<svg id="arrow-down" xmlns="http://www.w3.org/2000/svg" width="63.255" height="32.001" viewBox="0 0 63.255 32.001"><path id="arrow-down-2" data-name="arrow-down" d="M60.757,25.21,34.377,51.538a2.479,2.479,0,0,1-3.5,0L4.5,25.21,1,28.721,27.374,55.037a7.436,7.436,0,0,0,10.508,0L64.255,28.721Z" transform="translate(-1 -25.21)" fill="'+ $color + '"/></svg>');
}

.form-group {
  margin-bottom: 14px;
  position: relative;

  .btn {
    @include inline-block;
    margin-bottom: 10px;
    margin-right: 15px;
    width: auto;

    &:last-child {
      margin-right: 0;
    }
  }

  &.value {
    select {
      @include select-svg();
      border-color: $text-dark-color;

      + .form-label {
        font-size: 15px;
        top: -14px;
      }

      &.parsley-error,
      &.parsley-error~* {
        color: $primary-color !important;
      }

      &:focus {
        &.parsley-error,
        &.parsley-error~* {
          border-color: $primary-color;
          color:$primary-color !important;
        }
      }
    }
  }

  .button-wrap {
    margin-top: 0;

    .btn {
      min-width: 100%;
    }
  }
}

#dse{
    transform: scale(2);
    height: 20px;
    margin-right: 10px;
    margin-left: 5px;
}

*[type='text'] {
  margin-left: 0;
  width: 100%;
}

input,
select,
textarea,
.form-control {
  @include transition(all, 0.3s, ease, 0s);
  background-color: #fff;
  border: 1px solid $text-muted-color;
  border-radius: 5px;
  color: $input-color;
  height:34px;
  padding: 7px 11px;
  width: 100%;
  font-size: 14px;

  &:focus {
    border-color: $text-dark-color;
    box-shadow: none;
    color: $primary-color;
    outline: none;
    border: 2px solid $primary-color !important;

    + .form-label {
      color: $text-dark-color;
      font-size: 15px;
      top: -14px;
    }
  }

  &:not(:placeholder-shown):not(select){
        border-color: $text-dark-color;
        box-shadow: none;
        color: $primary-color;
        outline: none;
        border: 1px solid $text-muted-color;

        + .form-label {
          color: $text-dark-color;
          font-size: 15px;
          top: -14px;
        }
  }


  &:not(:placeholder-shown):not(select) {
    &.parsley-error,
    &.parsley-error~* {
      color: $primary-color !important;

      ~.error_info{
          display: none !important;
      }
    }



    &:focus {
      &.parsley-error,
      &.parsley-error~* {
        border-color: $primary-color;
        color: $primary-color !important;
      }
    }



  }
}

textarea{
    &.form-control{
        height: 80px;
    }
}

select {
  @include select-svg();
  -webkit-appearance: none;
  appearance: none;
  background-position: calc(100% - 12px) calc(100% - 12px);
  background-repeat: no-repeat;
  background-size: 14px;
  cursor: pointer;

  &.parsley-error {
    @include select-svg(#b40000);
  }
}

select::-ms-expand {
  display: none;
}

textarea {
  height: 180px;
  resize: none;
}

*[type='radio'],
*[type='checkbox'] {
  width: auto;

  &:focus {
    outline: none;
  }
}

.parsley-custom-error-message,
.parsley-error,
.parsley-required,
.parsley-type {
  color: $input-error-color;
  border: 2px solid #b40000;
}

.parsley-error,
.parsley-error~* {
  border-color: #b40000;
 // border: 2px solid #b40000;
  color: #b40000 !important;

  &:focus {
    border-color: #b40000;
    border: 2px solid #b40000;
    color: #b40000 !important;
  }
}
label{
    span{
        &.cbl{
            &.valid_error{
                color: #b40000 !important;
            }
        }
    }
    small{
        &.error_info{
            display: none;
            color: #b40000 !important;
        }
    }
}

.error_info{
    display: none;
    color: #b40000 !important;
}

.form-label {
  @include transition(all, 0.3s, ease-in-out, 0s);
  background-color: $white;
  color: $text-muted-color;
  font-size: 15px;
  left: 10px;
  line-height: normal;
  max-height: 27px;
  max-width: calc(100% - 46px);
  overflow: hidden;
  padding: 2px 5px 0;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 4px;
  white-space: nowrap;
}

.custom-checkbox,
.custom-radio {
  color: $text-dark-color;
  display: block;
  font-family: $font-secondary;
  margin-bottom: 10px;
  min-height: 34px;
  padding-left: 45px;
  position: relative;

  .h3 {
    display: block;
    font-weight: $semi-bold-weight;
  }

  a {
    color: $text-dark-color;
    font-weight: $bold-weight;

    &:hover {
      color: $secondary-color;
    }
  }

  input {
    height: 0;
    opacity: 0;
    width: 0;

    &:checked {
      + .checkmark {
        &::after {
          opacity: 1;
        }
      }
    }
  }

  .checkmark {
    @include inline-block;
    background-color: $white;
    border: 2px solid $dark-gray-color;
    border-radius: 3px;
    height: 33px;
    left: 0;
    position: absolute;
    top: 0;
    width: 29px;

    &::after {
      @include horizontal-vertical-align(absolute);
      @include transition(all, 0.2s, ease-in-out, 0s);
      content: '\f00c';
      font-family: 'Font Awesome 5 Pro';
      font-weight: $regular-weight;
      line-height: 33px;
      opacity: 0;
    }
  }
}

.custom-radio {
  .checkmark {
    border-radius: 50%;
    height: 30px;
    width: 30px;

    &::after {
      background-color: $primary-color;
      border-radius: 50%;
      content: '';
      font-family: $font-primary;
      height: 17px;
      width: 17px;
    }
  }
}

#form_response{
    display: none;
}

@include media-breakpoint-up(md) {
  .form-group {
    .button-wrap {
      .btn {
        min-width: 260px;
      }
    }
  }

  textarea {
    height: 240px;
  }

  .custom-checkbox,
  .custom-radio {
    margin-bottom: 20px;
  }
}

@include media-breakpoint-up(lg) {
  .form-group {
    .button-wrap {
      margin-top: 30px;
    }
  }
}
